import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "~/utils/theme-provider";
import UploadNewImage from "~/components/form/UploadNewImage";
import { FileWithPath } from "react-dropzone-esm";

type Props = {
  defaultValue?: string;
  defaultImage?: React.ReactNode;
};

export function ChangeImage({ defaultImage }: Props) {
  const { t } = useTranslation("translation");
  const [newImage, setNewImage] = useState<readonly FileWithPath[]>([]);
  let fileUploadRef = useRef<HTMLInputElement>(null);
  const [theme] = useTheme();

  useEffect(() => {
    if (!fileUploadRef.current) return;

    const dataTransfer = new DataTransfer();
    newImage.forEach((file) => {
      dataTransfer.items.add(file);
    });

    fileUploadRef.current.files = dataTransfer.files;

    // Help Safari out
    if (fileUploadRef.current.webkitEntries.length) {
      fileUploadRef.current.dataset.file = `${dataTransfer.files[0].name}`;
    }
  }, [newImage]);
  return (
    <>
      <div className="w-full ml-auto py-2">
        <UploadNewImage
          maxFiles={1}
          defaultImage={defaultImage}
          onAcceptedFilesChange={(files) => {
            setNewImage(files);
          }}
        />
        <input
          type="file"
          className="hidden"
          name="imageFile"
          ref={fileUploadRef}
        />
      </div>
    </>
  );
}
